<template>
  <div v-if="showRemainAmount" :class="$style.remain_amount">
    <p :class="$style.title">
      あと
      <span> &yen;{{ cartPriceState.remainAmountItem.remainAmount.toLocaleString() }} </span>
      購入で
      <span>{{ discountText }}</span>
      OFF!
    </p>
    <p :class="$style.description">※3,500円以下の商品は対象外です</p>
  </div>
</template>

<script lang="ts" setup>
import { useCart } from "~/composables/useCart"

const { cartPriceState } = useCart()

const route = useRoute()
const queryCouponCode = typeof route.query.coupon_code === "string" ? route.query.coupon_code : ""

const showRemainAmount = computed(() => {
  return !cartPriceState.usedCoupon.length && cartPriceState.remainAmountItem.remainAmount > 0 && !queryCouponCode
})
const discountText = computed(() => {
  if (cartPriceState.remainAmountItem.recommendDiscountMethod === "percentage") {
    return `${cartPriceState.remainAmountItem.recommendDiscountRate}%`
  }
  if (cartPriceState.remainAmountItem.recommendDiscountMethod === "fixed_amount") {
    return `${cartPriceState.remainAmountItem.recommendDiscountFixedAmount.toLocaleString()}`
  }
})
</script>

<style scoped module lang="scss">
.remain_amount {
  .title {
    @include main-font;
    font-size: $font-size-14;
    span {
      @include main-font;
      font-size: $font-size-18;
    }
  }
  .description {
    font-size: $font-size-12;
  }
}
</style>
