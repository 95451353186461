<template>
  <AtomsConsumerLink
    v-if="product && product.sku"
    :url="productLink(product)"
    :disabled="!productLink"
    :class="[$style.item, { [$style.is_vertical]: isVertical }]"
    @click="trackRecommendProductClick(product)"
  >
    <div :class="$style.thumb">
      <AtomsConsumerImage
        :src="product.sku.main_image || ''"
        :alt="product.name"
        :class="$style.img"
        :width="184 * 2"
        :height="245 * 2"
      />
    </div>
    <div :class="$style.item_detail">
      <div :class="$style.text_area">
        <p :class="$style.title">{{ product.name }}</p>
        <p :class="$style.material">{{ product.material }}</p>
        <p :class="$style.price">
          &yen; {{ calcPrice(product.sku).effective_reference_price_comma }}
          <span :class="$style.tax">(参考税込)</span>
        </p>
      </div>
      <AtomsConsumerButton variant="secondary" rounded block @click="addCartButton($event, product)">
        カートに追加
      </AtomsConsumerButton>
    </div>
  </AtomsConsumerLink>
</template>

<script setup lang="ts">
import { calcPrice } from "@tential/ec-gql-schema/utils/functions/price"
import { useStore } from "~/stores"
import type { RecommendProduct } from "~/types/server/api/recommend/side_cart"
import { getSiteProductPageUrl } from "~/utils/functions/product"

const store = useStore()

withDefaults(
  defineProps<{
    product?: RecommendProduct
    isVertical?: boolean
  }>(),
  {
    product: undefined,
    isVertical: false,
  },
)

const productLink = (product: RecommendProduct) => getSiteProductPageUrl(product)

const { trackRecommendProductClick } = useGtagEvent()

const addCartButton = (e: Event, product: RecommendProduct) => {
  e.preventDefault()
  store.setQuickViewProductId(product.document_id)
}
</script>

<style module scoped lang="scss">
.item {
  padding: 1rem;
  flex: 0 0 440px;
  background-color: $white;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  .thumb {
    width: 150px;
    flex-shrink: 0;
    overflow: hidden;
    .img {
      aspect-ratio: 184 / 245;
      object-fit: cover;
      height: 100%;
      width: 150px;
      border-radius: 0.375rem;
    }
  }
  .item_detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    gap: 1rem;
    .text_area {
      .title {
        @include main-font;
        font-size: $font-size-14;
      }
      .material {
        font-size: $font-size-12;
      }
      .price {
        @include main-font;
        font-size: $font-size-16;
        .tax {
          font-size: 10px;
          font-weight: 300;
          line-height: 14px;
          margin-left: 0.25rem;
        }
      }
    }
  }
  // 縦長
  &.is_vertical {
    padding: 0.5rem;
    background-color: $secondary-10;
    flex: 0 0 180px;
    flex-direction: column;
    max-width: none;
    .thumb {
      width: 100%;
      margin-bottom: 0.5rem;
      .img {
        width: 100%;
      }
    }
  }
}
</style>
