<template>
  <div v-if="recommendProducts.length > 0" :class="$style.recommendation">
    <div v-if="isSpColumn && !minScreenLg" :class="$style.item_columns">
      <template v-for="product in recommendProducts" :key="product.document_id">
        <CartRecommendCarouselItem :product="product" :class="$style.column" />
      </template>
    </div>
    <PagePartsProductListCarousel v-else :item-length="recommendProducts.length" :slides-to-scroll="1">
      <template #items>
        <template v-for="product in recommendProducts" :key="product.document_id">
          <CartRecommendCarouselItem :product="product" :is-vertical="isVertical" />
        </template>
      </template>
      <template #spItems>
        <template v-for="product in recommendProducts" :key="product.document_id">
          <CartRecommendCarouselItem :product="product" :is-vertical="isVertical" />
        </template>
      </template>
    </PagePartsProductListCarousel>
  </div>
</template>

<script setup lang="ts">
import type { RecommendProduct } from "~/types/server/api/recommend/side_cart"

const { width: windowWidth } = useWindowSize()
// @mixin lg と同じ値に設定
const minScreenLg = computed(() => windowWidth.value >= 1024)

type Props = {
  recommendProducts: RecommendProduct[]
  isVertical?: boolean
  isSpColumn?: boolean
}

withDefaults(defineProps<Props>(), {
  recommendProducts: () => [],
  isVertical: false,
  isSpColumn: false,
})
</script>

<style module scoped lang="scss">
.recommendation {
  @include container-lg;
  padding: 0 !important;
  margin-top: 1rem;
  .item_columns {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    .column {
      flex: auto;
    }
  }
}
</style>
